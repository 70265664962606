<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>类型：</span>
            <el-select v-model="pageInfo.type" placeholder="请选择类型" class="searchSelect" size="large">
              <el-option label="学生" :value="0" />
              <el-option label="教师" :value="1" />
            </el-select>
          </div>
          <div class="marginLeft20">
            <span>学号/教职工号：</span>
            <input v-model="pageInfo.code" placeholder="学号/教职工号" class="searchInput" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">预约签到列表</div>
        </div>
        <el-table :data="tableData" border row-key="id" @sort-change="sortChangeHandler"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" sortable="custom" label="编号" width="90" />
          <el-table-column label="学号 / 教职工号" >
            <template #default="{row}">
              <div>{{ row?.type === 0 ? row?.student?.code : row?.teacher?.code }}</div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template #default="{row}">
              <div>{{ row?.type === 0 ? '学生' : '教师' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="姓名">
            <template #default="{row}">
              <div>{{ row?.type === 0 ? row?.student?.name : row?.teacher?.name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="should" sortable="custom" label="应到" />
          <el-table-column prop="reality" sortable="custom" label="实到" />
          <el-table-column prop="dont" sortable="custom" label="未到" />
          <el-table-column prop="time" label="创建时间" />
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getReservationSignPage} from "@/api/examination";
export default {
  name: "statisticsList",
  data(){
    return{
      tableData:[],
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        order: 'desc',
        field: 'id',
        code: '',
        type: '',
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    sortChangeHandler({ order, prop }){
      this.pageInfo.order = order === 'descending' ? 'desc' : 'asc'
      this.pageInfo.field = prop
      this.getList()
    },
    reset(){
      this.pageInfo.code = ''
      this.pageInfo.type = ''
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getReservationSignPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>